// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component130Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full p-2 bg-gray-200 flex flex-row h-screen"}     > <div id='bloc0_0'  className={ "absolute bottom-0 right-0"}     > <div id='bloc0_0_0'  className={ "p-2 bg-primary_color rounded-full w-12 h-12 flex items-center justify-center"}     > <FontAwesomeIcon  id='bloc0_0_0_0'  icon={ fa.faAdd}      className={ "text-white h-6 w-6"}  /></div></div>
          <div id='bloc0_1'  className={ "w-1/5"}     > <div id='bloc0_1_0'  className={ "p-2"}     > <div id='bloc0_1_0_0'  className={ "w-full bg-transparent"}     > <div id='bloc0_1_0_0_0'  className={ "p-2 "}     > <div id='bloc0_1_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc0_1_0_0_0_0_0'  icon={ fa.faGears}        />
                    <div id='bloc0_1_0_0_0_0_1'  className={ " p-2"}     >{`Actions options`} </div></div></div></div>
              <div id='bloc0_1_0_1'  className={ "w-full bg-transparent"}     > <div id='bloc0_1_0_1_0'  className={ "p-2 "}     > <div id='bloc0_1_0_1_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc0_1_0_1_0_0_0'  icon={ fa.faGears}        />
                    <div id='bloc0_1_0_1_0_0_1'  className={ " p-2"}     >{`Actions options`} </div></div></div></div></div>
            <div id='bloc0_1_1'  className={ "p-2"}     > <div id='bloc0_1_1_0'  className={ "w-full p-2 bg-transparent p-2"}     > <div id='bloc0_1_1_0_0'  className={ "p-2 text-start"}     > <div id='bloc0_1_1_0_0_0'  className={ "font-bold text-white"}     >{`Rentrons à la maison`} </div>
                  <div id='bloc0_1_1_0_0_1'  className={ "text-gray-400"}     >{`26 titres | 1H04`} </div></div></div>
              <div id='bloc0_1_1_1'  className={ "w-full p-2 bg-transparent p-2"}     > <div id='bloc0_1_1_1_0'  className={ "p-2 text-start"}     > <div id='bloc0_1_1_1_0_0'  className={ "font-bold text-white"}     >{`Rentrons à la maison`} </div>
                  <div id='bloc0_1_1_1_0_1'  className={ "text-gray-400"}     >{`26 titres | 1H04`} </div></div></div>
              <div id='bloc0_1_1_2'  className={ "w-full p-2 bg-transparent p-2"}     > <div id='bloc0_1_1_2_0'  className={ "p-2 text-start"}     > <div id='bloc0_1_1_2_0_0'  className={ "font-bold text-white"}     >{`Rentrons à la maison`} </div>
                  <div id='bloc0_1_1_2_0_1'  className={ "text-gray-400"}     >{`26 titres | 1H04`} </div></div></div>
              <div id='bloc0_1_1_3'  className={ "w-full p-2 bg-transparent p-2"}     > <div id='bloc0_1_1_3_0'  className={ "p-2 text-start"}     > <div id='bloc0_1_1_3_0_0'  className={ "font-bold text-white"}     >{`Rentrons à la maison`} </div>
                  <div id='bloc0_1_1_3_0_1'  className={ "text-gray-400"}     >{`26 titres | 1H04`} </div></div></div></div></div>
          <div id='bloc0_2'  className={ "flex flex-row "}     > <div id='bloc0_2_0'  className={ "p-2  p-2"}     > <div id='bloc0_2_0_0'  className={ "p-2 bg-white"}     > <img  id='bloc0_2_0_0_0'  className={ "p-2 h-32   object-cover"}  src="undefined"   alt="undefined" /></div>
              <div id='bloc0_2_0_1'  className={ "p-2 text-start"}     > <div id='bloc0_2_0_1_0'  className={ "text-white text-bold"}     >{`Dance All Night`} </div>
                <div id='bloc0_2_0_1_1'  className={ "text-gray-500"}     >{`Titre | Chin Flame`} </div></div></div>
            <div id='bloc0_2_1'  className={ "p-2  p-2"}     > <div id='bloc0_2_1_0'  className={ "p-2 bg-white"}     > <img  id='bloc0_2_1_0_0'  className={ "p-2 h-32   object-cover"}  src="undefined"   alt="undefined" /></div>
              <div id='bloc0_2_1_1'  className={ "p-2 text-start"}     > <div id='bloc0_2_1_1_0'  className={ "text-white text-bold"}     >{`Dance All Night`} </div>
                <div id='bloc0_2_1_1_1'  className={ "text-gray-500"}     >{`Titre | Chin Flame`} </div></div></div>
            <div id='bloc0_2_2'  className={ "p-2  p-2"}     > <div id='bloc0_2_2_0'  className={ "p-2 bg-white"}     > <img  id='bloc0_2_2_0_0'  className={ "p-2 h-32   object-cover"}  src="undefined"   alt="undefined" /></div>
              <div id='bloc0_2_2_1'  className={ "p-2 text-start"}     > <div id='bloc0_2_2_1_0'  className={ "text-white text-bold"}     >{`Dance All Night`} </div>
                <div id='bloc0_2_2_1_1'  className={ "text-gray-500"}     >{`Titre | Chin Flame`} </div></div></div></div></div><div id='bloc1'  className={ "w-full bg-white"}     > <div id='bloc1_0'  className={ "relative"}     > <div id='bloc1_0_0'  className={ "flex flex-row justify-center items-center bg-primary_color text-white"}     > <div id='bloc1_0_0_0'  className={ "w-12 h-12 p-2 mr-32 flex flex-row gap-4 items-center"}     > <FontAwesomeIcon  id='bloc1_0_0_0_0'  icon={ fa.faQuestion}      className={ "flex flex-row"}  />
                <FontAwesomeIcon  id='bloc1_0_0_0_1'  icon={ fa.faQuestion}        />
                <FontAwesomeIcon  id='bloc1_0_0_0_2'  icon={ fa.faQuestion}        />
                <div id='bloc1_0_0_0_3'       > </div></div>
              <img  id='bloc1_0_0_1'  className={ "w-12 h-12 p-2   object-cover"}  src="https://fs.appisyou.com/users/21/Chromakopia_CD_cover1731923488242.jpg"   alt="undefined" />
              <div id='bloc1_0_0_2'  className={ "flex flex-col text-start px-2"}     > <div id='bloc1_0_0_2_0'  className={ "app-font-1 text-xs"}     >{`St.Chroma(avec Daniel Caesar)`} </div>
                <div id='bloc1_0_0_2_1'  className={ "app-font-1 text-xs"}     >{`Tyler, TheCreator | CHROMAKOPIA | 2024`} </div></div>
              <div id='bloc1_0_0_3'  className={ "ml-32 flex flex-row items-center gap-4"}     > <FontAwesomeIcon  id='bloc1_0_0_3_0'  icon={ fa.faQuestion}        />
                <FontAwesomeIcon  id='bloc1_0_0_3_1'  icon={ fa.faQuestion}        />
                <FontAwesomeIcon  id='bloc1_0_0_3_2'  icon={ fa.faQuestion}        /></div></div>
            <div id='bloc1_0_1'  className={ "absolute left-0 flex flex-row items-center -top-2 w-full"}     > <div id='bloc1_0_1_0'  className={ "absolute left-0 flex flex-row items-center top-0 z-20"}   style = { {"width":"65% "}}   > <div id='bloc1_0_1_0_0'  className={ "bg-white h-1 w-full"}     > </div>
                <div id='bloc1_0_1_0_1'  className={ "p-2 w-4 h-4 rounded-full bg-secondary_color"}     > </div></div>
              <div id='bloc1_0_1_1'  className={ "absolute left-0 flex flex-row items-center w-full h-4 top-0"}     > <div id='bloc1_0_1_1_0'  className={ "w-full bg-gray-500 h-1"}     > </div></div></div></div></div></>
  
}

export default Component130Page;
