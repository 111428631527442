// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component126Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc5_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc5_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "bg-white pt-1"}     > <div id='bloc0_0'  className={ "p-14 lg:p-24 bg-secondary_color h-screen flex"}     > <div id='bloc0_0_0'  className={ "p-2 items-center bg-primary_color justify-center flex flex-col w-full"}     > <div id='bloc0_0_0_0'  className={ "p-2 app-font-1 text-white text-center text-3xl w-3/5 m-2"}     >{`Bienvenue sur TimeLI, ta plateforme qui te permet de créer ta playlist parfaite`} </div>
              <div id='bloc0_0_0_1'  className={ "w-2/3 text-white w-1/3 text-6xl p-4"}     > </div></div></div></div><div id='bloc1'  className={ "bg-white pt-1"}     > <div id='bloc1_0'  className={ "p-14 lg:p-24 bg-secondary_color h-screen flex"}     > <div id='bloc1_0_0'  className={ "p-2 items-center bg-primary_color justify-center flex flex-col w-full"}     > <div id='bloc1_0_0_0'  className={ "p-2 app-font-1 text-white text-center text-3xl w-3/5 m-2"}     >{`Choisis ton mood du moment.`} </div>
              <div id='bloc1_0_0_1'  className={ "w-2/3 text-white w-1/3 text-6xl p-4"}     >{`????????????????????????`} </div></div></div></div><div id='bloc2'  className={ "bg-white pt-1"}     > <div id='bloc2_0'  className={ "p-14 lg:p-24 bg-secondary_color h-screen flex"}     > <div id='bloc2_0_0'  className={ "p-2 items-center bg-primary_color justify-center flex flex-col w-full"}     > <div id='bloc2_0_0_0'  className={ "p-2 app-font-1 text-white text-center text-3xl w-3/5 m-2"}     >{`Choisis ton style de musique`} </div>
              <div id='bloc2_0_0_1'  className={ "w-2/3 text-white w-1/3 text-6xl p-4"}     >{`????????????????????️????`} </div></div></div></div><div id='bloc3'  className={ "bg-white pt-1"}     > <div id='bloc3_0'  className={ "p-14 lg:p-24 bg-secondary_color h-screen flex"}     > <div id='bloc3_0_0'  className={ "p-2 items-center bg-primary_color justify-center flex flex-col w-full"}     > <div id='bloc3_0_0_0'  className={ "p-2 app-font-1 text-white text-center text-3xl w-3/5 m-2"}     >{`Choisis ton pays de référence musicale`} </div>
              <div id='bloc3_0_0_1'  className={ "w-2/3 text-white w-1/3 text-6xl p-4"}     >{`????️`} </div></div></div></div><div id='bloc4'  className={ "bg-white pt-1"}     > <div id='bloc4_0'  className={ "p-14 lg:p-24 bg-secondary_color h-screen flex"}     > <div id='bloc4_0_0'  className={ "p-2 items-center bg-primary_color justify-center flex flex-col w-full"}     > <div id='bloc4_0_0_0'  className={ "p-2 app-font-1 text-white text-center text-3xl w-3/5 m-2"}     >{`Et enfin choisis ton itinéraire ou la durée de ta playlist`} </div>
              <div id='bloc4_0_0_1'  className={ "w-2/3 text-white w-1/3 text-6xl p-4"}     >{`????⌚????‍➡️`} </div></div></div></div><Link    to="/home"><div id='bloc5'  className={ "app-font-1 text-app-color-3 bg-primary_color w-full p-2"}    onClick = { bloc5_onClick } > <div id='bloc5_0'       > <div id='bloc5_0_0'  className={ "p-2 w-full text-center"}     >{`Let's Go`} </div></div></div></Link></>
  
}

export default Component126Page;
